<template>
  <div class="container-excel">
      <section v-if="dataUsers.length > 0 && !dataIsValid">
          <hr>
          <div class="mb-3">
              <p class="h5"> <span class="number">1</span>  File selection </p>
          </div>
          <div>
              <b-alert :show="dismissCountDown" variant="success"  fade>
                <i class="ri-alert-line mr-3"></i>  ¡Su archivo se ha cargado verfique que la informacion que se muestra en pantalla se visualiza correctamente!
              </b-alert>
          </div>
          <div>
            <p class="h6 mb-3 ml-1">Preview</p>
            <b-table show-empty small   sticky-header striped hover :items="userPreview" :fields="headers"></b-table>
          </div>
          <div class="mx-auto" style="width: 100%;">
              <b-button variant="primary" @click.prevent="confirmData">Confirm File</b-button>
          </div>
      </section>
      <section v-if="dataUsers.length > 0 && dataIsValid">
          <hr>
          <div class="mb-3">
              <p class="h5"> <span class="number">2</span>  Field mapping </p>
          </div>
          <b-row class="mb-3">
              <b-col cols="3">ENCABEZADO DEL ARCHIVO</b-col>
              <b-col cols="5">DATO</b-col>
              <b-col cols="4">ATRIBUTO DEL USUARIO</b-col>
          </b-row>
          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form @submit.prevent="handleSubmit(confirmFile)">
              <div v-for="(header, index) in headers" :key="index">
                <b-row class="file-field">
                  <b-col cols="3" align-self="center"><span class="font-weight-bold">{{header.toUpperCase()}}</span></b-col>
                  <b-col cols="5">
                  <div v-for="(value, index) in getDatos(header)" :key="index" >
                    {{value}}
                  </div>
                  </b-col>
                  <b-col cols="4" align-self="center">
                    <ValidationProvider :name="'Select '+header" rules="required" v-slot="{ errors }">
                      <p v-if="showMessage(selected[index])" v-b-popover.hover.top="'Si el campo lleva  dos o mas registros, recuerde separar con punto y coma ( ; )'">
                        <i class="ri-alert-fill"></i>
                      </p>
                      <b-form-select  :id="'select'+index" :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="selected[index]" @change="validateSelecction(selected[index])" :options="userAttributes">
                      </b-form-select>
                      <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </div>
            </form>
          </ValidationObserver>
          <div v-if="alertError" class="mt-3">
              <b-alert show variant="danger" dismissible>
                <i class="ri-alert-line mr-3" ></i> <p v-html="message"></p>
              </b-alert>
          </div>
          <div v-if="alertWarning" class="mt-3">
              <b-alert show variant="warning" dismissible>
                <i class="ri-alert-line mr-3" ></i> <p v-html="messageWarning"></p>
              </b-alert>
          </div>
          <div v-if="alertSuccess" class="mt-3" >
              <b-alert show variant="success" dismissible>
                <i class="ri-alert-line mr-3" ></i> <p v-html="message"></p>
              </b-alert>
          </div>
          <div class="mx-auto" style="width: 100%;">
            <b-button v-if="showBtnConfirmMap" class="float-left" variant="primary" @click.prevent="confirmFile">Confirm Map</b-button>
            <b-button class="float-right" variant="secondary" @click.prevent="goBack()">Go back</b-button>
          </div>
      </section>
  </div>
</template>
<script>
import { core } from '../../config/pluginInit'

export default {
  name: 'UserLoad',
  mounted () {
  },
  data () {
    return {
      alertError: false,
      message: '',
      alertWarning: false,
      messageWarning: '',
      alertSuccess: false,
      dataIsValid: false,
      dismissCountDown: 7,
      showBtnConfirmMap: true,
      userAttributes: [
        { value: 'cancel', text: 'Do not import' },
        { value: 'mails', text: 'EMAIL' },
        { value: 'name', text: 'NOMBRE PROPIETARIO' },
        { value: 'phones', text: 'CELULAR' },
        { value: 'idProperty', text: 'USUARIO, APTO, CASA o ID' },
        { value: 'coefProperty', text: 'PORCENTAJE, COEFICIENTE PROPIEDAD' }
      ],
      selected: [],
      totalCoef: 0
    }
  },
  watch: {
    dataUsers (newValue, oldValue) {
      if (newValue.length !== 0 && oldValue.length !== 0) {
        this.selected = []
      }
      if (oldValue.length === 0 && newValue.length > 0) {
        this.formatSelected()
      } else {
        if (oldValue.length > 0 && newValue.length > 0) {
          this.formatSelected()
        }
      }
    }
  },
  props: {
    dataUsers: { type: Array, default: () => [] },
    headers: { type: Array, default: () => [] }
  },
  computed: {
    /**
     * Metodo computado que retorna una parte de los datos que vienes para una vista previa de estos
     */
    userPreview () {
      const user = []
      for (let i = 0; i < this.dataUsers.length; i++) {
        user.push(this.dataUsers[i])
        if (i === 5) {
          return user
        }
      }
      return user
    }
  },
  methods: {
    /**
     * Metodo que mapea todos los datos que estan separado por ; y valida que el separados no sea otro en los campo
     */
    validateMapData (mapData) {
      const filedWithAlerts = []
      const fieldsWithError = []
      // let isErrorlocal = false
      // let isErroroffice = false
      // let isErrorparkingLot = false
      // let isErrorusefulRoom = false
      // let isErrorname = false
      // let isErrordocument = false
      // TODO : si viene undifenide .split genera un error
      for (let i = 0; i < mapData.length; i++) {
        // Empezamos con los emails  a validar
        if (mapData[i].mails === undefined) {
          mapData[i].mails = ''
        } else {
          if (mapData[i].mails !== null) {
            const haveOtherSeparator = this.splitEmailWithOtherSeparator(mapData[i].mails)
            if (haveOtherSeparator) {
              fieldsWithError.push('Error en el correo: ' + mapData[i].mails + ' se encontro un correo separado con otro operador:  Registro # ' + (i + 1))
            } else {
              // Separamos por ; como se establece la regla de carga de excel
              mapData[i].mails = mapData[i].mails.split(';')
              for (let j = 0; j < mapData[i].mails.length; j++) {
                mapData[i].mails[j] = mapData[i].mails[j].replace(/ /g, '')
                if (!this.validateEmail(mapData[i].mails[j])) {
                  fieldsWithError.push('Error en el correo: ' + mapData[i].mails[j] + ' Registro # ' + (i + 1))
                }
              }
            }
          }
        }
        //   /**
        //    * Validacion para campos undefined, es decir campos que deberian
        //    * seleccionar o estar en el excel  y no los crearon o los pusieron como do not import
        //    */
        //   if (mapData[i].local === undefined) {
        //     isErrorlocal = true
        //   } else {
        //     if (mapData[i].local !== null) {
        //       const haveOtherSeparator = this.splitUtilsWithOtherSeparator(mapData[i].local)
        //       if (haveOtherSeparator) {
        //         fieldsWithError.push('Error en la columna de locales debe separar con punto y coma ( ; ):  Registro # ' + (i + 1))
        //       } else {
        //         // Separamos por ; como se establece la regla de carga de excel
        //         mapData[i].local = mapData[i].local.toString().split(';')
        //       }
        //     } else {
        //       fieldsWithError.push('Error en registro de local, recuerde llenar con un NO o 0 si no tiene local:  Registro # ' + (i + 1))
        //     }
        //   }
        //   if (mapData[i].office === undefined) {
        //     isErroroffice = true
        //   } else {
        //     // Validacion para oficina
        //     if (mapData[i].office !== null) {
        //       const haveOtherSeparator = this.splitUtilsWithOtherSeparator(mapData[i].office)
        //       if (haveOtherSeparator) {
        //         fieldsWithError.push('Error en la columna de oficinas: ' + mapData[i].office + ' debe separar con punto y coma ( ; ):  Registro # ' + (i + 1))
        //       } else {
        //         // Separamos por ; como se establece la regla de carga de excel
        //         mapData[i].office = mapData[i].office.toString().split(';')
        //       }
        //     } else {
        //       fieldsWithError.push('Error en registro de oficina, recuerde llenar con un NO o 0 si no tiene oficina:  Registro # ' + (i + 1))
        //     }
        //   }
        //   if (mapData[i].parkingLot === undefined) {
        //     isErrorparkingLot = true
        //   } else {
        //     // Validacion para parqueadero
        //     if (mapData[i].parkingLot !== null) {
        //       const haveOtherSeparator = this.splitUtilsWithOtherSeparator(mapData[i].parkingLot)
        //       if (haveOtherSeparator) {
        //         fieldsWithError.push('Error en la columna de parqueaderos: ' + mapData[i].parkingLot + ' debe separar con punto y coma ( ; ):  Registro # ' + (i + 1))
        //       } else {
        //         // Separamos por ; como se establece la regla de carga de excel
        //         mapData[i].parkingLot = mapData[i].parkingLot.toString().split(';')
        //       }
        //     } else {
        //       fieldsWithError.push('Error en registro de parqueaderos, recuerde llenar con un NO o 0 si no tiene parqueaderos:  Registro # ' + (i + 1))
        //     }
        //   }
        //   if (mapData[i].usefulRoom === undefined) {
        //     isErrorusefulRoom = true
        //   } else {
        //     // Validacion para cuarto util
        //     if (mapData[i].usefulRoom !== null) {
        //       const haveOtherSeparator = this.splitUtilsWithOtherSeparator(mapData[i].usefulRoom)
        //       if (haveOtherSeparator) {
        //         fieldsWithError.push('Error en la columna de cuartos utiles: ' + mapData[i].usefulRoom + ' debe separar con punto y coma ( ; ):  Registro # ' + (i + 1))
        //       } else {
        //         // Separamos por ; como se establece la regla de carga de excel
        //         mapData[i].usefulRoom = mapData[i].usefulRoom.toString().split(';')
        //       }
        //     } else {
        //       fieldsWithError.push('Error en registro de cuartos utiles, recuerde llenar con un NO o 0 si no tiene parqueaderos:  Registro # ' + (i + 1))
        //     }
        //   }
        //   if (mapData[i].name === undefined) {
        //     isErrorname = true
        //   } else {
        //     // Validacion para nombres
        //     if (mapData[i].name !== null) {
        //       const haveOtherSeparator = this.splitUtilsWithOtherSeparator(mapData[i].name)
        //       if (haveOtherSeparator) {
        //         fieldsWithError.push('Error en la columna propietario y/o apoderado: ' + mapData[i].name + ' debe separar con punto y coma ( ; ):  Registro # ' + (i + 1))
        //       } else {
        //         // Separamos por ; como se establece la regla de carga de excel
        //         mapData[i].name = mapData[i].name.toString().split(';')
        //         for (let j = 0; j < mapData[i].name.length; j++) {
        //           const verifyLongName = this.verifyLongName(mapData[i].name[j])
        //           if (verifyLongName) {
        //             filedWithAlerts.push('Campo nombre registra como ' + mapData[i].name[j] + ' Registro # ' + (i + 2))
        //           }
        //         }
        //       }
        //     } else {
        //       fieldsWithError.push('Error en registro de cuartos utiles, recuerde llenar con un NO o 0 si no tiene parqueaderos:  Registro # ' + (i + 1))
        //     }
        //   }
        //   if (mapData[i].document === undefined) {
        //     isErrordocument = true
        //   } else {
        //     // Validacion para documentos
        //     if (mapData[i].document !== null) {
        //       const haveOtherSeparator = this.splitUtilsWithOtherSeparator(mapData[i].document)
        //       if (haveOtherSeparator) {
        //         fieldsWithError.push('Error en la columna documento: ' + mapData[i].document + ' debe separar con punto y coma ( ; ):  Registro # ' + (i + 1))
        //       } else {
        //         // Separamos por ; como se establece la regla de carga de excel
        //         mapData[i].document = mapData[i].document.toString().split(';')
        //       }
        //     } else {
        //       fieldsWithError.push('Error en registro de cuartos utiles, recuerde llenar con un NO o 0 si no tiene parqueaderos:  Registro # ' + (i + 1))
        //     }
        //   }
        // }
        // if (isErrorlocal) {
        //   fieldsWithError.push('ERROR FATAL: Se ha encontrado que la columna de locales no ha sido importada o no fue cargada desde el excel, recuerde que esta columna debe estar creada y seleccionada en el mapeo de datos, de lo contrario no podra continuar con el proceso ')
        // }
        // if (isErroroffice) {
        //   fieldsWithError.push('ERROR FATAL: Se ha encontrado que la columna de oficinas no ha sido importada o no fue cargada desde el excel, recuerde que esta columna debe estar creada y seleccionada en el mapeo de datos, de lo contrario no podra continuar con el proceso ')
        // }
        // if (isErrorparkingLot) {
        //   fieldsWithError.push('ERROR FATAL: Se ha encontrado que la columna de parqueaderos no ha sido importada o no fue cargada desde el excel, recuerde que esta columna debe estar creada y seleccionada en el mapeo de datos, de lo contrario no podra continuar con el proceso ')
        // }
        // if (isErrorusefulRoom) {
        //   fieldsWithError.push('ERROR FATAL: Se ha encontrado que la columna de cuartos útiles no ha sido importada o no fue cargada desde el excel, recuerde que esta columna debe estar creada y seleccionada en el mapeo de datos, de lo contrario no podra continuar con el proceso ')
        // }
        // if (isErrorname) {
        //   fieldsWithError.push('ERROR FATAL: Se ha encontrado que la columna de nombres/propietarios no ha sido importada o no fue cargada desde el excel, recuerde que esta columna debe estar creada y seleccionada en el mapeo de datos, de lo contrario no podra continuar con el proceso ')
        // }
        // if (isErrordocument) {
        //   fieldsWithError.push('ERROR FATAL: La columna de DOCUMENTO no ha sido importada o no fue cargada desde el excel, recuerde que esta columna debe estar creada y seleccionada en el mapeo de datos, de lo contrario no podra continuar con el proceso ')
        // }
        if (filedWithAlerts.length > 0) {
          this.alertWarning = true
          this.messageWarning = 'Hemos encontrado campos algo inusuales, revisa que están separados con punto y coma ( ; ) o que no se halla filtrado información, si cree que esta bien haz caso omiso a esta alerta'
          for (let i = 0; i < filedWithAlerts.length; i++) {
            this.messageWarning += ('<br>' + filedWithAlerts[i])
          }
        }
        if (fieldsWithError.length === 0) {
          return mapData
        } else {
          this.alertError = true
          this.message = 'Errores encontrados: <br> Posibles errores<br>--> Correos no separados por coma ( , ).<br>--> Correos con espacios<br>--> Correos mal escritos, ya sea por falta del dominio o el simbolo @ <hr>'
          for (let i = 0; i < fieldsWithError.length; i++) {
            this.message += ('<br>' + fieldsWithError[i])
          }
          return []
        }
      }
    },
    /**
     * Retorna true y muestra una alerta de cuales campos deben estar separados con ;
     */
    showMessage (nameField) {
      if (nameField === 'name' || nameField === 'mails' || nameField === 'document' || nameField === 'parkingLot' || nameField === 'usefulRoom' || nameField === 'office' || nameField === 'local') {
        return true
      }
      return false
    },
    /**
     * Metodo para volver atrás
     */
    goBack () {
      this.totalCoef = 0
      this.showBtnConfirmMap = true
      this.dataIsValid = false
      this.alertError = false
      this.alertWarning = false
      this.messageWarning = ''
      this.alertSuccess = false
      this.$emit('hideExcelField', true)
    },
    /**
     * Metodo para formatear el selected, identifica los nombres en las cabeceras y si encuentra una coincidencia
     * le asigna el nombre del atributo que deberia de tener el usuario
     */
    formatSelected () {
      const mails = ['email', 'correo', 'correos', 'emails', 'Email ADMON']
      const document = ['documento', 'identificacion', 'cedula', 'document']
      const name = ['nombre', 'apellidos', 'nombres', 'usuarios', 'residente', 'propietario', 'Propietario', 'Apoderado', 'Propietario y/o Apoderado']
      const phones = ['telefono', 'celular', 'phones', 'contacto']
      const profession = ['profesion', 'oficio', 'ocupacion', 'profession', 'trabajo']
      const idProperty = ['casa', 'apartamento', 'APARTAMENTO', 'vivienda', 'hogar', 'propiedad']
      const block = ['bloque', 'torre', 'Torre']
      const parkingLot = ['parqueadero', 'parqueo', 'automoviles', 'PQ']
      const usefulRoom = ['cuarto', 'util', 'cuarto util', 'Útil']
      const local = ['Local', 'tienda']
      const office = ['Oficina']
      const coefProperty = ['Coef. Apto']
      const coefParkingLot = ['Coef. Parq']
      const coefUsefulRoom = ['Coef. C.útil']
      const coefOffice = ['Coef. Oficina']
      const coefLocal = ['Coef. Local']
      //
      const firstColumn = Object.values(this.headers)
      for (let i = 0; i < firstColumn.length; i++) {
        let exist = false
        let addMatch = ''
        for (let j = 0; j < mails.length; j++) {
          if (firstColumn[i].includes(mails[j])) {
            exist = true
            addMatch = 'mails'
          }
        } if (exist === false) {
          for (let j = 0; j < document.length; j++) {
            if (firstColumn[i].includes(document[j])) {
              exist = true
              addMatch = 'document'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < name.length; j++) {
            if (firstColumn[i].includes(name[j])) {
              exist = true
              addMatch = 'name'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < phones.length; j++) {
            if (firstColumn[i].includes(phones[j])) {
              exist = true
              addMatch = 'phones'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < profession.length; j++) {
            if (firstColumn[i].includes(profession[j])) {
              exist = true
              addMatch = 'profession'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < idProperty.length; j++) {
            if (firstColumn[i].includes(idProperty[j])) {
              exist = true
              addMatch = 'idProperty'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < block.length; j++) {
            if (firstColumn[i].includes(block[j])) {
              exist = true
              addMatch = 'block'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < parkingLot.length; j++) {
            if (firstColumn[i].includes(parkingLot[j])) {
              exist = true
              addMatch = 'parkingLot'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < usefulRoom.length; j++) {
            if (firstColumn[i].includes(usefulRoom[j])) {
              exist = true
              addMatch = 'usefulRoom'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < local.length; j++) {
            if (firstColumn[i].includes(local[j])) {
              exist = false
              addMatch = 'local'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < office.length; j++) {
            if (firstColumn[i].includes(office[j])) {
              exist = false
              addMatch = 'office'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < coefProperty.length; j++) {
            if (firstColumn[i].includes(coefProperty[j])) {
              exist = true
              addMatch = 'coefProperty'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < coefParkingLot.length; j++) {
            if (firstColumn[i].includes(coefParkingLot[j])) {
              exist = true
              addMatch = 'coefParkingLot'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < coefOffice.length; j++) {
            if (firstColumn[i].includes(coefOffice[j]) && firstColumn[i].includes('Coef')) {
              exist = true
              addMatch = 'coefOffice'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < coefUsefulRoom.length; j++) {
            if (firstColumn[i].includes(coefUsefulRoom[j]) && firstColumn[i].includes('Coef')) {
              exist = true
              addMatch = 'coefUsefulRoom'
            }
          }
        } if (exist === false) {
          for (let j = 0; j < coefLocal.length; j++) {
            if (firstColumn[i].includes(coefLocal[j]) && firstColumn[i].includes('Coef')) {
              exist = true
              addMatch = 'coefLocal'
            }
          }
        }
        this.selected.push(addMatch)
      }
    },
    /**
     * Metodo que valida si la data es valida para que pueda pasar a la otra seccion
     */
    confirmData () {
      this.dataIsValid = true
      this.$emit('hideExcelField', false)
    },
    async confirmFile () {
      const valid = await (this.$refs.observer.validate())
      if (valid) {
        this.validateAttributes()
        const mapData = this.formatData()
        if (mapData.length > 0) {
          const mapDataValidate = this.validateMapData(mapData)
          this.showCoefTotal(mapDataValidate)
          if (mapDataValidate.length > 0) {
            if (this.totalCoef > 100) {
              this.alertWarning = true
              this.messageWarning = 'Hemos encontrado que el coeficiente es superior al 100% es posible que tenga errores en calculo o intente subir el archivo con extension XLSX'
            } else {
              this.message = `La informacion ha sido mapeada satisfactoriamente \n El total de coeficiente calculado es de ${this.totalCoef.toFixed(4)} %, para continuar click en siguiente. `
              this.alertSuccess = true
              this.showBtnConfirmMap = false
              core.showSnackbar('success', 'The data has been map')
              this.$emit('saveDataMap', { data: mapDataValidate, totalCoef: this.totalCoef.toFixed(2) })
            }
          } else {
            core.showSnackbar('success', 'An error has ocurred')
          }
        } else {
          core.showSnackbar('success', 'An error has ocurred')
        }
      } else {
        this.validateAttributes()
      }
      // const firstColumn = Object.keys(this.dataUsers[0])
    },
    /**
     * Metodo que verifica que la longitud del nombre no sea una longitud muy grande, ya que esta puede ser
     * a que no se estan separando los nombres
     */
    verifyLongName (name) {
      if (name.length > 45) {
        return true
      }
      return false
    },
    /**
    * Metodo que verifica si el email esta separado con otro separador que no sea;
    */
    splitEmailWithOtherSeparator (data) {
      if (data.split(',').length > 1) {
        return true
      }
      if (data.split('/').length > 1) {
        return true
      }
      if (data.split(' ').length > 1) {
        return true
      }
      if (data.split(';').length > 1) {
        return true
      }
      return false
    },
    /**
    * Metodo que verifica si el parqueadero, cuarto util, local, oficina, identificacion, nombres
    * estas separado con otro separador que no sea ;
    */
    splitUtilsWithOtherSeparator (data) {
      let temp = data
      if (Number.isInteger(data)) {
        temp = data.toString()
      }
      if (temp.split('-').length > 1) {
        return true
      }
      if (temp.split(',').length > 1) {
        return true
      }
      if (temp.split('/').length > 1) {
        return true
      }
      return false
    },
    /**
     * Validacion sencilla para los correos
     */
    validateEmail (email) {
      const re = /^\S+@\S+\.\S+$/
      return re.test(email)
    },
    /**
     * Metodo que obtiene los primeros 3 datos para mostrar con respecto a la cabecera que se carga
     * desde el archivo excel
     */
    getDatos (header) {
      const data = []
      for (let i = 0; i < this.dataUsers.length; i++) {
        if (this.dataUsers[i][`${header}`] === undefined) {
          return data
        } else {
          data.push(this.dataUsers[i][`${header}`])
          if (i === 2) {
            return data
          }
        }
      }
      return data
    },
    /**
     * Metodo que valida que se haya seleccionado atributos para todas las filas que se ingresan en el excel
     */
    validateAttributes (headersExcel) {
      let existValueEmpty = false
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i] === '') {
          existValueEmpty = true
          this.alertError = true
          this.message = 'Debe seleccionar un atributo del usuario para todos los campos que registra el excel'
        }
      }
      if (!existValueEmpty) {
        this.alertError = false
        this.message = ''
      }
    },
    /**
     * Metodo que valida si el campo seleccionado ya no fue seleccionado por otro campo y cambiara
     * el estado de una alerta de informacion
     */
    validateSelecction (selection) {
      let count = 0
      let lastPosition = 0
      if (this.selected.length > 0) {
        for (let i = 0; i < this.selected.length; i++) {
          if (this.selected[i] === selection && selection !== 'cancel') {
            lastPosition = i
            count += 1
          }
        }
      }
      if (count > 1) {
        this.selected[lastPosition] = ''
        core.showSnackbar('error', 'Solo se le permite seleccionar una columna de datos con este atributo')
      }
    },
    /**
     * Metodo que formatea la informacion que viene en el excel,y  si se seleccion alguna columna para no importar se va a
     * quitar y además se le asignan los nombres a las llaves de una vez como debe de quedar en la base de datos, este
     * metodo retorna un nuevo arreglo con la informacion que se selecciono
     */
    formatData () {
      const mapData = []
      const fieldsEmpty = []
      // Recorro en un ciclo la data que esta en el excel
      for (let i = 0; i < this.dataUsers.length; i++) {
        // Creamos la variable donde se almacenara lo del nuev objecto
        const object = {}
        for (let j = 0; j < this.selected.length; j++) {
          if (this.selected[j] !== 'cancel') {
            // Debo validar cuando el campo esta vacio
            if (this.dataUsers[i][`${this.headers[j]}`] !== undefined) {
              object[`${this.selected[j]}`] = this.dataUsers[i][`${this.headers[j]}`]
            } else {
              // No se permitiran agregar ninguno de estos campos vacios
              if (this.selected[j] === 'document' ||
              this.selected[j] === 'idProperty' ||
              this.selected[j] === 'parkingLot' ||
              this.selected[j] === 'usefulRoom' ||
              this.selected[j] === 'office' ||
              this.selected[j] === 'local') {
                fieldsEmpty.push({ field: this.headers[j], row: i })
              } else {
                object[`${this.selected[j]}`] = null
              }
            }
          }
        }
        mapData.push(object)
      }
      if (fieldsEmpty.length > 0) {
        this.alertError = true
        this.message = 'Ha ocurrido un error debido a que hay algunos datos en el excel vacios, por favor revisar las siguientes filas y columnas y cargue nuevamente el excel corregido: '
        for (let i = 0; i < fieldsEmpty.length; i++) {
          this.message += ('<br>Columna de ' + fieldsEmpty[i].field + ' - Registro numero' + (fieldsEmpty[i].row + 1) + ' ')
        }
        return []
      }
      return mapData
    },
    showCoefTotal (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].coefProperty && data[i].coefProperty !== undefined) {
          this.totalCoef += data[i].coefProperty
        }
        if (data[i].coefParkingLot && data[i].coefParkingLot !== undefined) {
          this.totalCoef += data[i].coefParkingLot
        }
        if (data[i].coefUsefulRoom && data[i].coefUsefulRoom !== undefined) {
          this.totalCoef += data[i].coefUsefulRoom
        }
        if (data[i].coefLocal && data[i].coefLocal !== undefined) {
          this.totalCoef += data[i].coefLocal
        }
        if (data[i].coefOffice && data[i].coefOffice !== undefined) {
          this.totalCoef += data[i].coefOffice
        }
      }
    }
  }

}
</script>

<style scoped>
.number{
    border: 1px solid #cecece;
    font-size: 12px;
    font-weight: 800;
    -moz-border-radius: 0.8em;
    display: inline-block;
    font-weight: bold;
    line-height: 1.7em;
    margin-right: 13px;
    text-align: center;
    width: 1.9em;
    border-radius: 24.8em;
}

.file-field{
    padding: 5px;
    border: 1px solid #dadcfffb;
    margin-bottom: 5px;
    background: #f1f6ff;
}
.container-excel{
  width: 100%;
}
</style>
